.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 50px 24px 24px;
        border: 1px solid $color-main;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::placeholder {
            color: $color-main;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        height: 100%;
        position: absolute;
        top: 50%;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
            width: 23px;
            fill: $color-main;
            transition: all $duration;

            &:hover,
            &:focus {
                fill: $color-main--rgba;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;

    li {
        position: relative;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            color: $color-second;
            background: $color-main;
            padding: 15px 24px;
            border-bottom: 1px solid $color-second;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-second;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                background-color: $color-main;
                padding: 15px 24px;
                color: $color-second;
            }
        }

        &:not(.no-results) {
            a:hover {
                background-color: $color-main--rgba;
                padding-left: 35px;

                &::after {
                    color: $color-second;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-second;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;

                &::after {
                    color: $color-second;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-second;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-second;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-size: $font-size--1;
            font-weight: $font-weight-light;
            color: $color-main;
            text-transform: uppercase; 
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                color: $color-main;
                background: transparent;
                border-color: $color-main;
                transition: $duration;

                &:hover, 
                &:focus {
                    border-color: $color-gray;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        transition: all $duration;
        
        svg {
            width: 30px;
            height: 30px;
            fill: $color-main;
            transition: all $duration;
        }

        &:hover {
            svg {
                fill: $color-gray;
            }
        }
    }
}

.search__effect {
    position: absolute;
    top: -130px;
    left: 0;

    svg {
        width: 325px;
        height: 520px;
        fill: $color-main;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

    .search__effect {
        top: -90px;
    
        svg {
            width: 260px;
            height: 415px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

    .search__effect {
        top: -55px;
        left: -70px;
    
        svg {
            width: 200px;
            height: 320px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-container .searchInput {
        padding-left: 15px;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }
}

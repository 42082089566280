.breadcrumb {
    ul  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 5px;
    }

    li {
        padding: 5px;

        &:first-child {
            padding-left: 0;
        }

        a {
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: -2.5px;
                background-color: $color-gray;
                z-index: 1;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-gray;

                &::after {
                    width: 100%;
                }
            }
        }
    }

    svg {
        width: 24px;
        fill: $color-main;
    }
}
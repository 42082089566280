html {    
    --color-main                             : #1C1C1C;
    --color-text                             : #1C1C1C;
    --color-second                           : #FFFFFF;

    --color-main--rgb                        : 28, 28, 28;
    --color-second--rgb                      : 255, 255, 255;

    --color-main--rgba                       : rgba(28, 28, 28, .8);
    --color-second--rgba                     : rgba(255, 255, 255, .8);

    --color-light                            : #EFEFEF;
    --color-gray                             : #747474;

    --shadow-header                          : 0px 1px 0px rgba(28, 28, 28, 0.1);
    --shadow-kiosque                         : drop-shadow(0px 0px 1px rgba(28, 28, 28, 0.08)) drop-shadow(1px 1px 20px rgba(28, 28, 28, 0.05));

    &[data-theme="dark"] {
        --color-main                             : #FFFFFF;
        --color-text                             : #FFFFFF;
        --color-second                           : #1C1C1C;

        --color-main--rgb                        : 255, 255, 255;
        --color-second--rgb                      : 28, 28, 28;

        --color-main--rgba                       : rgba(255, 255, 255, .8);
        --color-second--rgba                     : rgba(28, 28, 28, .8);

        --color-light                            : #323232;
        --color-gray                             : #A5A5A5;

        --shadow-header                          : 0px 1px 0px rgba(255, 255, 255, 0.1);
        --shadow-kiosque                         : drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.08)) drop-shadow(1px 1px 20px rgba(255, 255, 255, 0.05));
    
        .wrapper #comarquage.espace-asso #co-bar #co-search input[type=submit], 
        .wrapper #comarquage.espace-part #co-bar #co-search input[type=submit], 
        .wrapper #comarquage.espace-pro #co-bar #co-search input[type=submit] {
            background: url(/wp-content/themes/noirmoutier-ile/assets/src/images/sprite-images/Pictos/ic_demarches_dark.svg) no-repeat 50%;
        
            &:hover,
            &:focus {
                background: url(/wp-content/themes/noirmoutier-ile/assets/src/images/sprite-images/Pictos/ic_demarches_dark_hover.svg) no-repeat 50%;
            }
        }

        .leaflet-control-geosearch form {
            color: #1C1C1C !important;
        
            input {
                color: #1C1C1C !important;
            }
        }
    }
}
.mailpoet_form,
.mailpoet-manage-subscription {
    padding: 0 !important; // !important nécessaire pour écraser le style de Mailpoet

    .mailpoet_paragraph {
        
        .mailpoet_text_label {
            margin-bottom: 5px;
            font-weight: $font-weight-bold !important; // !important nécessaire pour écraser l'inline style de Mailpoet
        }
        
        .mailpoet_text {
            height: 32px;
        }
        
        .mailpoet_segment_label {
            font-weight: $font-weight-bold !important; // !important nécessaire pour écraser l'inline style de Mailpoet
        }
        
        .mailpoet_required {
            color: $color-flexible-alert;
        }

        // submit du formulaire            
        [type=submit] {
            width: unset !important; // !important nécessaire pour écraser le style de Mailpoet
            display: inline-flex;
            justify-content: center;
            align-items: center;
            appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            margin: 5px 14px 5px 0 !important; // !important nécessaire pour écraser l'inline style de Mailpoet
            padding: $btn-padding !important; // !important nécessaire pour écraser l'inline style de Mailpoet
            background: $btn-bg;
            border: $btn-border-width $btn-border-style $btn-border-color !important; // !important nécessaire pour écraser l'inline style de Mailpoet
            border-radius: $btn-border-radius;
            font-family: $btn-font-family;
            font-weight: $btn-font-weight;
            font-size: $btn-font-size;
            line-height: $btn-line-height;
            color: $btn-color;
            text-transform: $btn-text-transform;
            text-decoration: none;
            text-align: center;
            transition: all $duration ease;
            
            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked {
                color: $btn-color--hover;
                background: $btn-bg--hover;
                border-color: $btn-border-color--hover;
                
                svg {
                    fill: $btn-color--hover;
                }
            }
            
            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: all $duration ease;
            }
        }
            

        a {
            text-decoration: underline !important;

            &:hover {
                text-decoration: none !important;
            }
        }
        
    }

    .mailpoet_paragraph:nth-last-of-type(3) .mailpoet_checkbox_label {
        font-weight: $font-weight-bold !important; // !important nécessaire pour écraser l'inline style de Mailpoet
    }

    // messages d'erreur
    input.parsley-error,
    select.parsley-error,
    textarea.parsley-error {
        background: $color-bg--body !important; // !important nécessaire pour écraser le style de Mailpoet
        color: $color-text !important; // !important nécessaire pour écraser le style de Mailpoet
        transition: $duration;
    }

    .parsley-required,
    .parsley-custom-error-message {
        font-size: 1rem;
        font-weight: 700;
        color: $color-flexible-alert;
        border-left: 6px solid $color-flexible-alert;
        padding-left: 24px;
        height: 44px;
        line-height: 1;
        background: $redlight;
        display: flex;
        align-items: center;
    }

    .parsley-errors-list {
        color: $red;
    }
    
}

// archives
.mailpoet_archive {

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    li {
        position: relative;
        padding-left: 18px;

        &::before {
            content: '';
            background: $color-dark;
            width: 11px;
            height: 11px;
            border-radius: 50px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
}

// modifier votre abonnement
.mailpoet-manage-subscription {
    .mailpoet_paragraph {
        // h2 fictif
        .mailpoet_segment_label {
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            font-family: $font-family--heading;
            font-weight: $font-weight--heading !important;
            line-height: $line-height--heading;
            font-size: $font-size--2;
            color: $color-main;
            border-bottom: $border-width $border-style $color-main;
            padding-bottom: 10px;
        }
    
        &:last-child {
            margin-top: 15px;
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .mailpoet-manage-subscription .mailpoet_segment_label {
        font-size: 1.750rem; //28px
    }

}

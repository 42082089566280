.footer {
    padding-bottom: 50px;
    margin-top: 70px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__top {
        display: flex;
    }

    &__logo {
        svg {
            width: 190px;
            height: 126px;
            fill: $color-main;
        }
    }

    &__mairie {
        width: 215px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 110px;
    }

    &__title {
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-main;
        margin: 0;
    }

    &__text {
        line-height: 1.3em;
        font-weight: $font-weight-normal;
        color: $color-main;
        margin-top: 16px;
    }

    &__tel {
        display: flex;
        align-items: center;
        column-gap: 10px;
        line-height: 1.3em;
        font-weight: $font-weight-normal;
        color: $color-main;
        margin-top: 12px;

        svg {
            width: 21px;
            height: 8px;
            fill: $color-main;
            transition: $duration;
        }

        &:hover,
        &:focus {
            column-gap: 5px;
        }
    }

    &__horaires {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 85px;
    }

    &__contact {
        margin: 45px 0 0 auto;
    }

    &__bottom {
        margin-top: 80px;
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__link a {
        position: relative;
        font-size: $font-size--text-small;
        line-height: 1.65em;
        font-weight: $font-weight-normal;
        color: $color-main;
        text-transform: uppercase;
        padding: 7.5px 0;

        &::after {
            content: '';
            width: 15px;
            height: 1px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: $color-main;
            z-index: 1;
            transition: $duration;
        }

        &:hover,
        &:focus {
            &::after {
                width: 55px;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        padding-bottom: 45px;
    
        &__logo {
            svg {
                width: 150px;
            }
        }
    
        &__mairie {
            margin-left: 50px;
        }
    
        &__horaires {
            margin-left: 45px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__top {
            flex-direction: column;
            align-items: center;
        }
    
        &__mairie,
        &__horaires {
            align-items: center;
            margin-top: 15px;
            margin-left: 0;
        }

        &__horaires {
            margin-top: 34px;
        }


        &__title,
        &__text {
            text-align: center;
        }

        &__text {
            margin-top: 13px;
        }

        &__tel {
            justify-content: center;
        }

        &__contact {
            margin: 35px auto 0;
        }

        &__bottom {
            margin-top: 37px;
        }

        &__menu {
            width: 410px;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 25px;
            column-gap: 40px;
            margin: 0 auto;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {   
        padding-bottom: 32px; 

        &__menu {
            width: 100%;
        }
    }
}
html[data-theme="dark"] .tools__item--mode {
    &__light {
        transform: rotate(180deg) scale(0);
        opacity: 0;
    }

    &__dark {
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

body {
    &.admin-bar.accessibility {
        .header {
            top: 94px;
        }

        iframe.goog-te-menu-frame.skiptranslate {
            top: 264px !important;
        }
    }

    &.admin-bar.sticky {
        iframe.goog-te-menu-frame.skiptranslate {
            top: 112px !important;
        }
    }

    &.admin-bar {
        .header {
            top: 32px;
        }

        iframe.goog-te-menu-frame.skiptranslate {
            top: 202px !important;
        }
    }

    &.accessibility {
        .header {
            top: 62px;
        }

        iframe.goog-te-menu-frame.skiptranslate {
            top: 232px !important;
        }
    }

    &.sticky {
        #content {
            margin-top: 80px;
        }

        iframe.goog-te-menu-frame.skiptranslate {
            top: 80px !important;
        }
    }
}

#accessconfig {
    display: flex;
}

.header {
    width: 100%;
    height: 170px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-second;
    z-index: 990;
    transition: $duration;

    &__container {
        height: 100%;
        display: flex;
        align-items: flex-end;
        transition: $duration;

    }

    &-logo {
        flex-shrink: 0;
        margin-bottom: 25px;

        &__big,
        &__small {
            svg {
                width: 170px;
                fill: $color-main;
            }
        }

        &__small {
            display: none;
            opacity: 0;
            visibility: hidden;
        }

        &__identity {
            display: none;
        }
    }

    &__menu {
        flex: 1;
        display: flex;
        justify-content: space-between;
    }

    &--fixed {
        height: 80px;
        box-shadow: $shadow-header;

        .header {
            &-logo {
                height: 80px;
                display: flex;
                align-items: center;
                margin-bottom: 0;

                &__big {
                    display: none;
                }
    
                &__small {
                    display: flex;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        #nav-main {
            .menu__nav__list {
                height: 80px;

                .nav-main-link {
                    height: 100%;
                    padding: 0;

                    &::after {
                        bottom: 15px;
                    }
                }
            }
        }

        .tools {
            height: 80px;
            margin-bottom: 0;
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 80px !important;
        // }
    }
}

.tools {
    // Google translate
    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 55px;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-second;
            // border: 1px solid $color-second;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px $color-main--rgba);
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-second;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
                transition: none;
                color: $color-text;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-second;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-main;
                }
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    align-items: center;
    column-gap: 22px;
    margin: 0 0 25px auto;
    transition: $duration;

    &__item {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;

        svg {
            width: 35px;
            height: 35px;
            fill: $color-main;
            transition: $duration;

            .st0 {
                fill: $color-main;
                transition: $duration;
            }

            .st1 {
                fill: $color-second;
                transition: $duration;
            }
        }

        span {
            font-size: $font-size--text;
            line-height: 1.5em;
            font-weight: $font-weight-normal;
            color: $color-main;
            text-transform: none;
            transition: $duration;
        }

        &--search {
            column-gap: 10px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        &--mode {
            width: 35px;
            height: 35px;
            position: relative;

            input {
                width: 35px;
                height: 35px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 2;

                &:hover {
                    cursor: pointer;
                }
            }

            &__light,
            &__dark {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                transition: 1s;
            }

            &__dark {
                transform: rotate( -180deg ) scale(0);
                opacity: 0;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &--translate {
            display: flex;
        }

        &:hover,
        &:focus {
            background-color: transparent;

            svg {
                fill: $color-main;
            }
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }

    &-view {
        width: 100%;
        position: absolute;
        top: 170px;
        left: 0;
        z-index: 1;


        // #google_translate_element {
        //     display: none;
        // }
    }
}


// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 170px !important;
//     left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
//     border: none;
//     box-shadow: $shadow;
// }


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        // body {
        //     &.admin-bar.accessibility {        
        //         iframe.goog-te-menu-frame.skiptranslate {
        //             top: 244px !important;
        //         }
        //     }

        //     &.admin-bar {        
        //         iframe.goog-te-menu-frame.skiptranslate {
        //             top: 182px !important;
        //         }
        //     }
        // }  
        
        .header {
            height: 150px;
        
            &-logo {        
                &__big,
                &__small {
                    svg {
                        width: 140px;
                    }
                }
            }

            &--fixed {
                height: 80px;
            }
        }
        
        
        .tools {
            column-gap: 10px;
        
            &__item {                        
                &--search {   
                    margin-right: 8px;

                    svg {
                        width: 23px;
                        height: 22px;
                    }
                }
            }
        
            &-view {
                top: 150px;        
            }
        }
        
        
        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 150px !important;
        //     left: calc(100% - ((100% - 960px) / 2) - 335px) !important;
        // }
    }


    // 960
    @media screen and (max-width: $medium) {    
        body {
            &.admin-bar.accessibility .header,
            &.admin-bar .header,
            &.accessibility .header {        
                top: unset;
            }

            // &.admin-bar.accessibility {
            //     iframe.goog-te-menu-frame.skiptranslate {
            //         top: 368px !important;
            //     }
            // }

            // &.admin-bar {
            //     iframe.goog-te-menu-frame.skiptranslate {
            //         top: 196px !important;
            //     }
            // }

            // &.accessibility {
            //     iframe.goog-te-menu-frame.skiptranslate {
            //         top: 262px !important;
            //     }
            // }
        }  

        .header {
            position: relative;
            top: unset;
            left: unset;
            
            &-logo {   
                margin-bottom: 19px;

                &__big,
                &__small {
                    svg {
                        width: 150px;
                    }
                }
            }
        }
        
        
        .tools {
            column-gap: 15px;
            margin: 0 0 29px 30px;
        
            &__item {                        
                &--search {   
                    margin-right: 0;
                         
                    svg {
                        width: 30px;
                        height: 30px;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     left: calc(100% - (100% - 640px)/2 - 335px)!important;
        // }

        .privacy {
            &__container {
                flex-wrap: wrap;
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }


    // 640
    @media screen and (max-width: $small) {
        body {
            // &.admin-bar.accessibility {
            //     iframe.goog-te-menu-frame.skiptranslate {
            //         top: 433px !important;
            //     }
            // }

            // &.admin-bar {        
            //     iframe.goog-te-menu-frame.skiptranslate {
            //         top: 261px !important;
            //     }
            // }

            &.accessibility {
                iframe.goog-te-menu-frame.skiptranslate {
                    top: 387px !important;
                }
            }
        } 

        .header {
            height: 215px;

            &__container {
                flex-direction: column;
                align-items: center;
                row-gap: 23px;
            }

            &-logo {   
                margin: 20px 0 0;
            }

            &__menu {
                width: 100%;
                flex: unset;
            }
        }
        
        .tools {
            margin: 0;
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 215px !important;
        //     left: 50% !important;
        //     transform: translateX(-50%);
        // }
    }


    // @media screen and (max-width: 430px) {
    //     body {
    //         &.admin-bar.accessibility {
    //             iframe.goog-te-menu-frame.skiptranslate {
    //                 top: 491px !important;
    //             }
    //         }

    //         &.accessibility {
    //             iframe.goog-te-menu-frame.skiptranslate {
    //                 top: 447px !important;
    //             }
    //         }
    //     } 
    // }
.cover {
    padding-bottom: 20px;

    &-image {
        &__img {
            width: 100%;
            height: 450px;
            background: $color-bg--image;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    &-infos {
        margin-top: 25px;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
        }

        &__title {
            color: $color-main;
            margin: 0;
        }

        &__text {
            font-size: 24px;
            line-height: 1.3em;
            font-weight: $font-weight-bold;
            color: $color-main;
            margin: 18px 0 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {    
            &-image {
                &__img {
                    height: 380px;
                }
            }
        
            &-infos {
                margin-top: 20px;
        
                &__text {
                    margin: 15px 0 0;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {    
            &-image {
                &__img {
                    height: 350px;
                }
            }
        }
    }


// 640
@media screen and (max-width: $small) {
    .cover {    
        &-image {
            .container {
                max-width: 100%;
                padding: 0;
            }

            &__img {
                height: 320px;
            }
        }

        &-infos {    
            &__text {
                font-size: $font-size--text-medium;
            }
        }
    }
}
#nav-main {
    margin-left: 55px;
    transition: $duration;

    .menu__nav__list {
        display: flex;
        transition: $duration;
    } 

    .nav-main-item {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $duration;

        &:first-child {
            width: 131.5px;
        }

        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 178.5px;
        }

        &:last-child {
            width: 180px;
        }

        &.menu-item-has-children:hover,
        &.menu-item-has-children:focus,  
        &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size--text-medium;
            font-weight: $font-weight-light;
            color: $color-main;
            text-transform: uppercase;
            padding: 10px 0 32px;
            transition: $duration;

            &::after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: 20px;
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }
        }

        .sub-menu {
            display: none;
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: linear-gradient(0deg, rgba($color-main--rgb, 0.1), rgba($color-main--rgb, 0.1)), $color-second;
            padding: 30px 15px;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, 
            .nav-grandchild-item {
                width: 100%;
                display: inline-flex;
                line-height: initial;
                margin: -1px; // Fix space beetween inline-flex

                a {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    line-height: 1.2em;
                    font-weight: $font-weight-extrabold;
                    color: $color-main;
                    padding: 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex

                    &::before {
                        content: "";
                        width: 50px;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: 5px;
                        background: $color-main;
                        transition: $duration;
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            width: 100px;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    font-size: $font-size--text;
                    font-weight: $font-weight;
                    padding: 10px 0;

                    &::before {
                        display: none;
                    }

                    &:hover,
                    &:before {
                        padding-left: 10px;
                    }
                } 
            }
        }

        &:hover,
        &:focus {
            cursor: pointer;

            .nav-main-link::after {
                width: 100%;
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    font-size: $font-size--text-medium;
    font-weight: $font-weight-light; 
    color: $color-main;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;

    .navicon {
        width: 20px;
        height: 1px;
        position: relative;
        display: block;
        background-color: $color-main;
        margin-right: 10px;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background: $color-main;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        #nav-main {
            margin-left: 20px;

            .nav-main-item {
                &:first-child {
                    width: 106.5px;
                }
        
                &:nth-child(2) {
                    width: 95px;
                }
        
                &:nth-child(3) {
                    width: 153.5px;
                }
        
                &:last-child {
                    width: 155px;
                }

                .sub-menu {
                    .nav-child-item a {
                        font-size: $font-size--text;
                    }

                    .nav-grandchild-item a {
                        font-size: $font-size--text-small;
                    }
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        #nav-main {
            margin: 5px 0 30px auto;

            .menu__nav__list {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 150px;
                left: 0;
                background: linear-gradient(0deg, rgba($color-main--rgb, 0.1), rgba($color-main--rgb, 0.1)), $color-second;
            }

            .nav-main-item {
                width: 100% !important;
                flex-direction: column;

                .nav-main-link {
                    width: 100%;
                    position: relative;
                    color: $color-main;
                    padding: 20px 15px;

                    &::after {
                        content: '';
                        width: 75px;
                        height: 1px;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                        background-color: $color-main;
                        z-index: 1;
                    }
                }

                &:last-child {
                    .nav-main-link {
                        &::after {
                            width: 0;
                        }
                    }

                    .sub-menu {
                        border-bottom: none;
                    }
                }

                .sub-menu {
                    border-bottom: 1px solid $color-main;
                }


                &.menu-item-has-children:hover, 
                &.menu-item-has-children:focus,
                &.menu-item-has-children:focus-within {
                    .sub-menu {
                        display: none;
                    }
                }

                &.active,
                &.menu-item-has-children.active {
                    .nav-main-link::after {
                        width: 100%;
                    }

                    .sub-menu {
                        position: relative;
                        display: flex;

                        .nav-drop {
                            column-count: 1;
                        }

                        .nav-child-item a {
                            font-size: $font-size--5;
                            color: $color-main;

                            &::before {
                                background-color: $color-main;
                            }
                        }

                        .nav-grandchild-item a {
                            font-size: $font-size--text;
                            color: $color-main;
                        }
                    }
                }

            }

            // Responsive button
            .nav-main_button {
                ~ .menu {
                    clear: both;
                    transform: scaleY(0);
                    transform-origin: 50% 0;
                    transition:transform .3s ease;
                }

                &:checked {
                    ~ .menu {
                        transform: scaleY(1);
                    }

                    ~ .nav-main_icon {
                        .navicon {
                            background-color: transparent;
                            
                            &:before {
                                transform: rotate(-45deg);
                            }

                            &:after {
                                transform: rotate(45deg);
                            }
                        }

                        &:not(.steps) {
                            .navicon {
                                &:before,
                                &:after {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }

            // Responsive button label
            .nav-main_icon {
                display: flex;
            }
        }
    }


    
    // 640
    @media screen and (max-width: $small) {
        #nav-main {
            margin: 0;

            .menu__nav__list {
                top: 215px;
            }
        }
    }
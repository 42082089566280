//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    column-gap: 80px;
    padding: 20px 0;
    margin: 20px 0;
    border-top: 1px solid $color-main;
    border-bottom: 1px solid $color-main;

    li {
        position: relative;

        a, 
        button,
        div {
            height: auto;
            position: relative;
            display: flex;
            align-items: center;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-main;
            text-transform: uppercase;
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;

            &::after {
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: transparent;
                z-index: 1;
                transition: $duration;
            }

            svg {
                width: 26px;
                height: 26px;
                fill: $color-main;
                margin-right: 14px;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-main;
                background-color: transparent;

                &::after {
                    width: calc(100% - 40px);
                    background-color: $color-main;
                }

                svg {
                    fill: $color-main;
                }
            }
                


        }

        &:last-child a,
        &:last-child button,
        &:last-child div {
            border-right: 0;
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {
        .action-share {
            opacity: 0;
        }

        .share-list {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
            display: flex;
            column-gap: 10px;

            li {
                a, 
                button, 
                div {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    opacity: 1;
                    transition: $duration;

                    svg {
                        margin: 0;
                        transform: scale(1.3);
                    }

                    #post_url {
                        position: absolute;
                        opacity: 0;
                    }

                    &.close {
                        background-color: transparent;
                        border: 1px solid $color-main;
                        border-radius: $border-radius--round;

                        svg {
                            transform: scale(1.3);
                        }

                        &:hover,
                        &:focus {
                            background-color: $color-main;
                            transform: scale(1);

                            svg {
                                fill: $color-second;
                            }
                        }
                    }

                    &:hover, 
                    &:focus {
                        transform: scale(1.2);
                    }
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-main;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-second;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }
        }
    }

}

section[data-cpt=maps] .actions-post {
    margin-top: 0;
}

//======================================================================================================
// Responsive
//======================================================================================================
    // 960
    @media screen and (max-width: $medium) {
        .actions-post {
            &.shared-on {
                margin: 60px auto 20px;

                .share-list {
                    display: flex;
                    position: absolute;
                    top: -55px;
                    left: -160px;
                }

                .action-share {
                    opacity: 1;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .actions-post {
            justify-content: space-between;
            column-gap: 0;
            margin: 20px auto;
            padding: 10px 0;

            > li {
                > a, button, div {
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    margin: 0 20px;
                    padding: 0;

                    span {
                        display: none;
                    }

                    svg {
                        fill: $color-main;
                        margin: 0;
                    }

                    &:hover, 
                    &:focus, 
                    &:active {
                        svg {
                            fill: $color-main;
                        }
                    }
                }
            }

            &.shared-on {
                .share-list {
                    top: -45px;
                    left:-210px;

                    button {
                        margin: 0;

                        svg {
                            fill: $color-main;
                        }
                    }
                }
            }

        }

    }
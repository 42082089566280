//======================================================================================================
// Général
//======================================================================================================
#content {
    margin-top: 170px;
    transition: $duration;
}

.front-page,
.footer {
    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4 {
        padding: 0;
        margin: 0;
        border: 0;

        &::after {
            display: none;
        }
    }
}

.home {    
    &-title {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        &__small {
            position: relative;
            font-family: 'Abril Fatface';
            font-size: 30px;
            line-height: 1em;
            font-weight: $font-weight-normal;
            color: $color-main;
            letter-spacing: 0.045em;
            padding-left: 49px;

            &::before {
                content: '';
                width: 40px;
                height: 1px;
                position: absolute;
                top: 60%;
                left: 0;
                transform: translateY(-50%);
                background-color: $color-main;
                z-index: 1;
            }
        }

        &__big {
            font-size: $font-size--1;
            line-height: 1em;
            font-weight: $font-weight-light;
            color: $color-main;
            text-transform: uppercase;
        }
    }

    &-button {
        height: 54px;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: $font-weight-normal;
        color: $color-main;
        text-transform: uppercase;
        background-color: transparent;
        padding: 0 30px 0 45px;
        border: 1px solid $color-main;
        transition: $duration;

        svg {
            width: 41px;
            position: absolute;
            top: 50%;
            left: -20px;
            transform: translateY(-50%);
            fill: $color-main;
            z-index: 1;
            transition: $duration;
        }

        &:hover,
        &:focus {
            color: $color-second;
            background-color: $color-main;

            svg {
                left: -10px;
                fill: $color-second;
            }
        }
    }
}



//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    .slideshow {
        width: 100%;
        position: relative;

        .swiper-slide {
            display: flex;
        }

        img, 
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &__item {
            width: 100%;
            position: relative;
            display: flex;

            &[href] {
                &:hover,
                &:focus {
                    .slideshow {
                        &__content {
                            background-color: $color-light;
                        }

                        &__title {
                            color: $color-main;
                
                            &::after {
                                background-color: $color-main;
                            }
                        }

                        &__text {
                            color: $color-main;
                        }
                    }
                }
            }
        }

        &__img {
            height: 595px;
            background-color: $color-bg--image;
        }

        &__content {
            width: 430px;
            position: absolute;
            left: -45px;
            bottom: 130px;
            background-color: $color-main;
            padding: 45px 70px 50px 50px;
            z-index: 2;
            transition: $duration;
        }

        &__title {
            position: relative;
            font-size: $font-size--4;
            font-weight: $font-weight-normal;
            color: $color-second;
            letter-spacing: 0.045em;
            text-transform: uppercase;
            margin: 0;
            transition: $duration;

            &::before,
            &::after {
                content: '';
                width: 35px;
                height: 1px;
                position: absolute;
                top: 15px;
                z-index: 1;
                transition: $duration;
            }

            &::before {
                left: -85px;
                background-color: $color-main;
                
            }

            &::after {
                display: block !important;
                left: -50px;
                background-color: $color-second;
            }
        }

        &__text {
            line-height: 1.4em;
            color: $color-second;
            margin-top: 10px;
            transition: $duration;
        }

        &-pagination {
            width: 100%;
            position: absolute;
            left: 50%;
            bottom: 35px;
            transform: translateX(-50%);
            display: flex;
            z-index: 2;

            &__items {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 15px;
                margin-left: 35px;
        
                .swiper-pagination-bullet {
                    width: 15px;
                    height: 15px;
                    background-color: transparent;
                    border: 1px solid $color-white;
                    border-radius: $border-radius--round;
                    box-shadow: 1px 1px 20px #1B2336;
                    opacity: 1;
                    transition: $duration;
        
                    &-active,
                    &:hover {
                        background-color: $color-white;
                    }
                }
            }
        }

        &--video {
            img {
                display: none;
            }
        }
    }
}



//======================================================================================================
// Quick Access
//======================================================================================================
.qa {
    position: relative;
    margin-top: 46px;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__title {
        font-family: 'Abril Fatface';
        font-size: $font-size--1;
        line-height: .9em;
        font-weight: $font-weight-normal;
        color: $color-main;
    }

    &__items {
        display: flex;
        column-gap: 56px;
    }

    &-item {
        width: 120px;
        height: 150px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &::after {
            content: '';
            width: 15px;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -2px;
            background-color: $color-main;
            z-index: 1;
            transition: $duration;
        }

        svg {
            width: 90px;
            height: 90px;
            fill: $color-main;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--6;
            line-height: 1.25em;
            font-weight: $font-weight-normal;
            color: $color-main;
            text-transform: uppercase;
        }

        &:hover,
        &:focus {
           &::after {
            width: 60px;
           } 

           svg {
            transform: scale(1.1);
           }
        }
    }
}



//======================================================================================================
// Actualités
//======================================================================================================
.ac {
    margin-top: 122px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &_button {
        &--responsive {
            display: none;
        }
    }

    &-bigitem {
        position: relative;
        margin-top: 50px;

        &__img {
            width: 770px;
            height: 390px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            width: 470px;
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-second;
            padding: 45px 30px 25px 45px;
            z-index: 2;
        }

        &__title {
            font-size: $font-size--2;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-main;
            margin-bottom: 14px !important;
        }

        &__text {
            font-size: $font-size--5;
            line-height: 1.3em;
            font-weight: $font-weight-normal;
            color: $color-main;
            margin-bottom: 30px;
        }

        &__more {
            position: relative;
            line-height: 1.25em;
            font-weight: $font-weight-normal;
            color: $color-main;
            text-transform: uppercase;
            padding-left: 45px;
            transition: $duration;

            &::before {
                content: '';
                width: 35px;
                height: 1px;
                position: absolute;
                top: 60%;
                left: 0;
                transform: translateY(-50%);
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }
        }

        &:hover,
        &:focus {
            .ac-bigitem {
                &__img img {
                    transform: scale(1.2);
                }

                &__more {
                    padding-left: 70px;

                    &::before {
                        width: 60px;
                    }
                }
            }
        }
    }

    &__smallitems {
        display: flex;
        column-gap: 30px;
        margin-top: 40px;
    }

    &-smallitem {
        display: flex;
        flex-direction: column;

        &__img {
            width: 370px;
            height: 210px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 21px 0 0;
            z-index: 2;
        }

        &__title {
            position: relative;
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-bold;
            color: $color-main;

            &::after {
                content: '';
                width: 35px;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: -14px;
                display: flex !important;
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }
        }

        &__text {
            line-height: 1.4em;
            font-weight: $font-weight-normal;
            color: $color-main;
            margin-top: 28px;
        }

        &:hover,
        &:focus {
            .ac-smallitem {
                &__img img {
                    transform: scale(1.2);
                }

                &__title::after {
                    width: 75px;
                }
            }
        }
    }

    &__button {
        &--responsive {
            display: none;
        }
    }
}



//======================================================================================================
// Agenda
//======================================================================================================
.ag {
    margin-top: 110px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-bigitem {
        position: relative;
        margin-top: 50px;

        &__img {
            width: 100%;
            height: 470px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            width: 470px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-second;
            padding: 35px 35px 0 0;
            margin-top: -92px;
            z-index: 2;
        }

        &__dates {
            font-size: $font-size--text-medium;
            line-height: 1.7em;
            color: $color-main;
            text-transform: uppercase;
        }

        &__title {
            font-size: $font-size--2;
            line-height: 1.25em;
            font-weight: $font-weight-bold;
            color: $color-main;
            margin-top: 11px !important;
        }

        &__more {
            position: relative;
            line-height: 1.25em;
            font-weight: $font-weight-normal;
            color: $color-main;
            text-transform: uppercase;
            padding-left: 45px;
            margin-top: 26px;
            transition: $duration;

            &::before {
                content: '';
                width: 35px;
                height: 1px;
                position: absolute;
                top: 60%;
                left: 0;
                transform: translateY(-50%);
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }
        }

        &:hover,
        &:focus {
            .ag-bigitem {
                &__img img {
                    transform: scale(1.2);
                }

                &__more {
                    padding-left: 70px;

                    &::before {
                        width: 60px;
                    }
                }
            }
        }
    }

    &__smallitems {
        display: flex;
        column-gap: 30px;
        margin-top: 60px;
    }

    &-smallitem {
        display: flex;
        flex-direction: column;

        &__img {
            width: 570px;
            height: 285px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            width: 470px;
            height: 160px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-second;
            padding: 23px 30px 0;
            margin: -30px auto 0;
            z-index: 2;
        }

        &__dates {
            font-size: $font-size--text-medium;
            line-height: 1.7em;
            color: $color-main;
            text-transform: uppercase;
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.225em;
            font-weight: $font-weight-bold;
            color: $color-main;
            margin-top: 4px !important;
        }

        &:hover,
        &:focus {
            .ag-smallitem {
                &__img img {
                    transform: scale(1.2);
                }
            }
        }
    }

    &__button {
        &--responsive {
            display: none;
        }
    }
}



//======================================================================================================
// Structures
//======================================================================================================
.structures {
    margin-top: 139px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        margin-left: 600px;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 30px;
        column-gap: 30px;
        margin-top: 30px;
    }

    &-item {
        position: relative;

        &__img {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 52.08%);
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }
        }

        &__title {
            position: absolute;
            left: 0;
            bottom: 20px;
            font-size: $font-size--text-medium;
            line-height: 1.2em;
            font-weight: $font-weight-normal;
            color: $color-white;
            text-transform: uppercase;
            text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
            padding: 0 60px 0 20px;
            z-index: 2;
        }

        &__icon {
            position: absolute;
            right: 23px;
            bottom: 15px;
            opacity: 0;
            z-index: 2;
            transition: $duration;

            svg {
                width: 18px;
                height: 18px;
                fill: $color-white;
            }
        }

        &--1 {
            margin-top: -115px;

            .structures-item__img {
                width: 570px;
                height: 395px;
            }
        }

        &--2 {
            .structures-item__img {
                width: 570px;
                height: 280px;
            }
        }

        &--3 {
            .structures-item__img {
                width: 670px;
                height: 310px;
            }
        }

        &--4 {
            .structures-item__img {
                width: 470px;
                height: 400px;
            }
        }

        &[href]:hover,
        &[href]:focus {
            .structures-item {
                &__img img {
                    transform: scale(1.1);
                }

                &__icon {
                    opacity: 1;
                }
            }
        }
    }
}



//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    &__container {
        position: relative;
        display: flex;
    }

    &__effect {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        svg {
            width: 325px;
            height: 520px;
            fill: $color-main;
        }
    }

    &__title {
        &--responsive {
            display: none;
        }
    }

    &-item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 92px 0 0 85px;

        &__buttons {
            display: flex;
            flex-direction: column;
            row-gap: 25px;
            margin-top: 83px;

            &--responsive {
                display: none;
            }
        }
    
        &__button {   
            position: relative;
            display: flex;
            flex-direction: column; 
            align-items: flex-end;
            row-gap: 10px;
            padding-bottom: 10px;

            &::after {
                content: '';
                width: 30px;
                height: 1px;
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: $color-main;
                z-index: 1;
                transition: $duration;
            }

            svg {
                width: 24px;
                height: 24px;
                fill: $color-main;
                transition: $duration;
            }

            span {
                line-height: 1.25em;
                font-weight: $font-weight-normal;
                color: $color-main;
                text-transform: uppercase;
            }
    
            &:hover,
            &:focus {
                &::after {
                    width: 75px;
                }

                svg {
                    transform: scale(1.2);
                }
            }
        }

        &__image {
            width: 270px;
            height: 382px;
            flex-shrink: 0;
            background-color: $color-bg--image;
            margin-left: 29px;
            filter: var(--shadow-kiosque);
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            }
        }
    
        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 117px 0 0 40px;
        }

        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__text {
            font-size: $font-size--5;
            line-height: 1.225em;
            font-weight: $font-weight-normal;
            color: $color-main;
            text-transform: uppercase;
            margin-top: 50px;
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.225em;
            font-weight: $font-weight-bold;
            color: $color-main;
            margin-top: 10px !important;
        }
    }

    &__button {
        &--responsive {
            display: none;
        }
    }
}



//======================================================================================================
// Newsletter
//======================================================================================================
.newsletter {
    margin-top: 125px;

    &__container {
        position: relative;
    }

    &__img {
       width: 1170px;
       height: 480px;
       background-color: $color-bg--image;
    
       img {
           width: 100%;
           height: 100%;
           object-fit: cover;
       }

       &--responsive {
        display: none;
       }
    }

    &__content {
        width: 405px;
        height: 335px;
        position: absolute;
        top: 46px;
        left: -45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-main;
    }

    &-title {
        align-items: flex-end;

        &__small,
        &__big {
            color: $color-second;
        }

        &__small {
            font-size: 25px;
            line-height: 1.2em;
            padding-left: 32px;

            &::before {
                width: 25px;
                background-color: $color-second;
            }
        }

        &__big {
            font-size: 35px;
            line-height: 1.2em;
        }
    }

    &__socials {
        width: 300px;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
    }

    &__social {
        svg {
            width: 44px;
            height: 44px;
            fill: $color-second;
            transition: $duration;
        }

        &:hover,
        &:focus {
            svg {
                transform: scale(1.2);
            }
        }
    }

    &__button {
        width: 300px;
        justify-content: center;
        color: $color-second;
        text-align: center;
        border-color: $color-second;
        padding: 0;
        margin-top: 20px;
        margin-left: 10px;
        transition: $duration;

        svg {
            fill: $color-second;
        }

        &:hover,
        &:focus {
            color: $color-main;
            background-color: $color-second;

            svg {
                fill: $color-main;
            }
        }
    }
}




//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        #content {
            margin-top: 150px;
        }



        .home-button {
            padding: 0 20px 0 35px;
        }



        .home-slideshow {
            .slideshow {
                &__content {
                    width: 400px;
                    left: -20px;
                    bottom: 97px;
                    padding: 45px 55px 50px 40px;
                }

                &__img {
                    height: 480px;
                }

                &__title {
                    &::before,
                    &::after {
                        width: 25px;
                    }

                    &::before {
                        left: -65px;
                        background-color: $color-main;
                        
                    }
        
                    &::after {
                        left: -40px;
                        background-color: $color-second;
                    }
                }

                &-pagination {
                    bottom: 25px;

                    &__items {
                        margin-left: 25px;
                    }
                }
            }
        }



        .qa {
            &__items {
                column-gap: 40px;
            }

            &-item {
                width: 100px;
                height: 135px;

                &::after {
                    bottom: 0;
                }

                svg {
                    width: 80px;
                    height: 80px;
                }
            }
        }



        .ac {
            margin-top: 120px;

            &-bigitem {
                &__img {
                    width: 620px;
                    height: 340px;
                }

                &__content {
                    width: 380px;
                    padding: 45px 0px 20px 35px;
                }

                &__title {
                    margin-bottom: 10px !important;
                }

                &__text {
                    margin-bottom: 20px;
                }
            }

            &__smallitems {
                column-gap: 20px;
                margin-top: 30px;
            }

            &-smallitem {
                &__img {
                    width: 300px;
                    height: 170px;
                }

                &__content {
                    padding: 17px 0 0;
                }
            }
        }



        .ag {
            margin-top: 94px;

            &-bigitem {
                &__img {
                    height: 380px;
                }

                &__content {
                    width: 400px;
                    padding: 35px 25px 0 0;
                }
            }

            &__smallitems {
                column-gap: 20px;
            }

            &-smallitem {
                &__img {
                    width: 460px;
                    height: 230px;
                }

                &__content {
                    width: 400px;
                    margin: -25px auto 0;
                }
            }
        }



        .structures {
            margin-top: 97px;

            &__title {
                margin-left: 480px;
            }

            &__items {
                row-gap: 20px;
                column-gap: 20px;
            }

            &-item {
                &__title {
                    bottom: 23px;
                }

                &__icon {
                    right: 24px;
                    bottom: 20px;
                }

                &--1 {
                    margin-top: -115px;

                    .structures-item__img {
                        width: 460px;
                        height: 340px;
                    }
                }

                &--2 {
                    .structures-item__img {
                        width: 460px;
                        height: 225px;
                    }
                }

                &--3 {
                    .structures-item__img {
                        width: 540px;
                        height: 250px;
                    }
                }

                &--4 {
                    .structures-item__img {
                        width: 380px;
                        height: 310px;
                    }
                }
            }
        }



        .kiosque {
            margin-top: 20px;

            &__effect {
                svg {
                    width: 260px;
                    height: 415px;
                }
            }

            &-item {
                margin: 70px 0 0 60px;

                &__buttons {
                    margin-top: 30px;
                }

                &__image {
                    width: 200px;
                    height: 285px;
                    margin-left: 25px;
                }
            
                &__content {
                    margin: 17px 0 0 21px;
                }
            }
        }



        .newsletter {
            margin-top: 140px;

            &__img {
                width: 940px;
                height: 385px;
            }

            &__content {
                top: 25px;
                left: -20px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        [data-aos] {
            visibility: visible;
            transform: none;
        }

        #content {
            margin-top: 0;
        }
        

        .home-slideshow {
            .slideshow {
                &__item {
                    flex-direction: column;
                }

                &__content {
                    width: 570px;
                    position: relative;
                    left: unset;
                    bottom: unset;
                    padding: 33px 50px 30px 40px;
                    margin: -20px auto 0;
                }

                &__img {
                    height: 320px;
                }

                &-pagination {
                    top: 270px;
                    bottom: unset;
                }

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }
            }
        }



        .qa {
            &__title {
                display: none;
            }

            &__items {
                width: 480px;
                flex-wrap: wrap;
                justify-content: space-between;
                row-gap: 45px;
                column-gap: 30px;
                margin: 0 auto;
            }
        }



        .ac {
            margin-top: 105px;

            &-bigitem {
                &__content {
                    width: 470px;
                    position: relative;
                    right: unset;
                    bottom: unset;
                    padding: 35px 50px 0px 0px;
                    margin-top: -35px;
                }

                &__title {
                    margin-bottom: 8px !important;
                }
            }

            &__smallitems {
                width: 470px;
                flex-direction: column;
                row-gap: 39px;
                margin-top: 60px;
            }

            &-smallitem {
                width: 100%;

                &__img {
                    width: 100%;
                    height: 260px;
                }
            }
        }



        .ag {
            margin-top: 119px;

            &-bigitem {
                &__img {
                    height: 250px;
                }

                &__content {
                    padding: 19px 30px 0 0;
                    margin-top: -44px;
                }
            }

            &__smallitems {
                column-gap: 20px;
            }

            &-smallitem {
                width: 300px;

                &__img {
                    width: 100%;
                    height: 150px;
                }

                &__content {
                    width: 100%;
                    padding: 9px 0 0;
                    margin: 0;
                }
            }
        }



        .structures {
            margin-top: 97px;

            &__title {
                margin-left: 320px;
            }

            &__items {
                margin-top: 14px;
            }

            &-item {
                &__title {
                    bottom: 15px;
                    padding: 0 60px 0 15px;
                }

                &--1 {
                    margin-top: -99px;

                    .structures-item__img {
                        width: 300px;
                        height: 221px;
                    }
                }

                &--2 {
                    .structures-item__img {
                        width: 300px;
                        height: 147px;
                    }
                }

                &--3 {
                    margin-top: -25px;

                    .structures-item__img {
                        width: 300px;
                        height: 139px;
                    }
                }

                &--4 {
                    .structures-item__img {
                        width: 300px;
                        height: 245px;
                    }
                }
            }
        }



        .kiosque {
            margin-top: 40px;

            &__effect {
                left: -55px;

                svg {
                    width: 200px;
                    height: 319px;
                }
            }

            &-item {
                margin: 70px 0 0 0;

                &__buttons {
                    display: none;
                    flex-direction: row;
                    column-gap: 30px;
                    margin: 24px 0 15px;

                    &--responsive {
                        display: flex;
                    }
                }

                &__button {
                    padding-bottom: 0;

                    &::after {
                        display: none;
                    }

                    svg {
                       width: 35px;
                       height: 35px; 
                    }
                }

                &__image {
                    width: 300px;
                    height: 430px;
                    margin-left: 0;
                }
            
                &__content {
                    height: 100%;
                    margin: 0 0 0 40px;
                }

                &__text {
                    margin-top: 40px;
                }
            }

            &__button {
                display: none;
                margin: auto 0 0;

                &--responsive {
                    display: flex;
                }
            }
        }



        .newsletter {
            margin-top: 130px;

            &__img {
                width: 620px;
                height: 254px;
            }

            &__content {
                width: 300px;
                height: 245px;
                top: -20px;
                left: 30px;
            }

            &-title {                
                &__small {
                    font-size: $font-size--text-medium;
                    line-height: 1.5em;
                }
        
                &__big {
                    font-size: 25px;
                }
            }

            &__socials {
                width: 220px;
                margin-top: 20px;
            }

            &__social svg {
                width: 35px;
                height: 35px;
            }

            &__button {
                width: 220px;
                text-align: right;
                margin-top: 15px;

                svg {
                    width: 30px;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .home-slideshow {
            .slideshow {
                .container {
                    max-width: 100%;
                    padding: 0;
                }

                &__content {
                    width: 300px;
                    padding: 33px 40px 40px;
                }

                &__img {
                    height: 330px;
                }

                &-pagination {
                    width: 300px;
                    top: 280px;
                    left: 50%;
                    transform: translateX(-50%);

                    &__items {
                        margin-left: 0;
                    }
                }
            }
        }



        .qa {
            margin-top: 40px;

            &__items {
                width: 260px;
            }
        }



        .ac {
            margin-top: 99px;

            &__button {
                display: none;

                &--responsive {
                    display: flex;
                    margin: 50px auto 0 20px;
                }
            }

            &-bigitem {
                width: 100%;

                &__img {
                    width: 100%;
                    height: 165px;
                }

                &__content {
                    width: 100%;
                    padding: 20px 0 0;
                    margin-top: 0;
                }

                &__title {
                    margin-bottom: 6px !important;
                }

                &__text {
                    margin-bottom: 15px;
                }
            }

            &__smallitems {
                width: 300px;
                margin-top: 40px;
            }

            &-smallitem {
                &__img {
                    height: 170px;
                }
            }
        }



        .ag {
            margin-top: 121px;

            &__button {
                display: none;

                &--responsive {
                    display: flex;
                    margin: 10px auto 0 20px;
                }
            }

            &-bigitem {
                &__img {
                    height: 150px;
                }

                &__content {
                    width: 100%;
                    padding: 15px 0 0;
                    margin-top: 0;
                }

                &__title {
                    margin-top: 10px !important;
                }

                &__more {
                    margin-top: 15px;
                }
            }

            &__smallitems {
                flex-direction: column;
                row-gap: 29px;
                margin-top: 50px;
            }

            &-smallitem {
                &__content {
                    padding: 9px 0 0;
                }
            }
        }



        .structures {
            margin-top: 95px;
            
            &__title {
                margin-left: 0;
            }

            &__items {
                margin-top: 39px;
            }

            &-item {
                &--1 {
                    order: 1;
                    margin-top: 0;
                }

                &--2 {
                    order: 3;
                }

                &--3 {
                    order: 2;
                    margin-top: 0;
                }

                &--4 {
                    order: 4;
                }
            }
        }



        .kiosque {
            margin-top: 117px;

            &__container {
                flex-direction: column;
            }

            &__effect {
                top: 263px;
                left: -70px;
            }

            &__title {
                display: none;

                &--responsive {
                    display: flex;
                }
            }

            &-item {
                flex-direction: column;
                margin-top: 39px;

                &__buttons {
                    margin: 19px 0 26px;
                }
            
                &__content {
                    height: auto;
                    margin: 0;
                }

                &__text {
                    margin-top: 45px;
                }
            }

            &__button {
                margin: 0 0 0 20px;
            }
        }



        .newsletter {
            margin-top: 135px;

            .container {
                max-width: 100%;
                padding: 0;
            }

            &__img {
                height: 260px;
            }

            &__content {
                top: -35px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 40px 0;

    .return {
        a {
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-main;
            padding: 5px 0;
            transition: $duration;

            &::after {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                right: 0;
                bottom: -8px;
                background-color: $color-main;
                z-index: 1;
                transition: $duration
            }

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-main;
                transition: $duration;
            }

            &:hover {
                color: $color-main--rgba;

                &::after {
                    width: 0;
                    background-color: transparent;
                }

                svg {
                    fill: $color-main--rgba;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }


// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
        min-width: 115px;

        .navigation_link {
            color: $color-main;

            // Recup style ~ ligne 9
            a {
                position: relative;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                color: $color-main;
                padding: 5px 0;
                transition: $duration;
                width: fit-content;
            }
        }
    }

    .prev {
        .navigation_link {
            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    bottom: -8px;
                    background-color: $color-main;
                    z-index: 1;
                    transition: $duration
                }
            }
        }
    }

    .next {
        .navigation_link {
            display: flex;
            justify-content: flex-end;

            a {
                &::after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-main;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }

                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    bottom: -8px;
                    background-color: $color-main;
                    z-index: 1;
                    transition: $duration
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-main--rgba;

                svg {
                    fill: $color-main--rgba;
                }

                a {
                    &::before, &::after {
                        background-color: $color-main--rgba;
                    }
                }
            }
        }
    }

    .prev {
        &:hover, &:focus, &:focus-within {
 
            .navigation_link a {
                &::after {
                    width: 0;
                    background-color: transparent;
                }
            }
        }
    }
 
    .next {
        &:hover, &:focus, &:focus-within {
 
            .navigation_link a {
                &::before {
                    width: 0;
                    background-color: transparent;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}
